import { Box } from '@trmediaab/zebra';
import { formatPostalCode } from '@trmediaab/zebra-utils';
import PropTypes from 'prop-types';

import { detailedUserType } from '~/main/prop-types';

const Address = ({ user, showName }) => {
  const { name, address: { address, careOf, city, postalCode } = {} } = user;

  if ([address, careOf, postalCode, city].filter(Boolean).length === 0) {
    return null;
  }

  const postalCodeAndCity = [formatPostalCode(postalCode), city]
    .filter(Boolean)
    .join(' ');

  return (
    <>
      {showName && name != null ? <Box>{name}</Box> : null}
      {careOf != null && careOf.length > 0 ? <Box>c/o {careOf}</Box> : null}
      {address != null && address.length > 0 ? <Box>{address}</Box> : null}
      {postalCodeAndCity.length > 0 ? <Box>{postalCodeAndCity}</Box> : null}
    </>
  );
};

Address.propTypes = {
  user: detailedUserType.isRequired,
  showName: PropTypes.bool,
};

Address.defaultProps = {
  showName: false,
};

export default Address;
