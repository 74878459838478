import {
  Anchor,
  BorderBox,
  Box,
  Divider,
  FlexBox,
  List,
  Stack,
} from '@trmediaab/zebra';
import Link from 'next/link';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useMemo } from 'react';

import PageHeader from '~/components/PageHeader';
import useAuthUser from '~/main/useAuthUser';
import useResponsiveLayout from '~/utils/useResponsiveLayout';

import LogoutButton from '../LogoutButton';

const getLayoutByBreakpoint = breakpoints =>
  window.innerWidth >= Number.parseInt(breakpoints.lg, 10) ? 'sidebar' : 'row';

const NavLayout = ({ children, ...pageHeaderProps }) => {
  const router = useRouter();
  const user = useAuthUser();

  const layout = useResponsiveLayout(getLayoutByBreakpoint);

  const navItems = useMemo(() => {
    const items = [
      {
        title: 'Prenumeration',
        href: '/',
      },
      {
        title: 'Kontouppgifter',
        href: '/konto',
      },
      {
        title: 'Inloggningsuppgifter',
        href: '/inloggning',
      },
      {
        title: 'Senaste köp',
        href: '/kop',
      },
      {
        title: 'Klippkort',
        href: '/klippkort',
      },
      {
        title: 'Betalningsmetoder',
        href: '/betalning',
      },
      user.hasAccessedJokersystemet && {
        title: 'Joker Live',
        href: '/joker',
      },
    ];

    return items.filter(Boolean);
  }, [user.hasAccessedJokersystemet]);

  return (
    <Stack pt="5" between="5">
      <PageHeader showBackLink={false} {...pageHeaderProps} />
      <FlexBox flexDirection={['column', null, null, 'row']}>
        {layout === 'row' && (
          <List
            axis="horizontal"
            display="flex"
            overflowX="auto"
            gap="3,5"
            mb="4"
            css={`
              -webkit-overflow-scrolling: touch;
            `}
          >
            {navItems.map(({ title, href }) => (
              <List.listItem
                key={title}
                css={`
                  flex-shrink: 0;
                `}
              >
                <BorderBox
                  borderBottom="3"
                  pb="2"
                  borderColor={
                    router.pathname === href ? 'primary' : 'transparent'
                  }
                >
                  <Anchor
                    as={Link}
                    href={href}
                    fontSize="2"
                    variant="none"
                    textDecoration="none"
                    color={router.pathname === href ? 'text.base' : 'greys.3'}
                    whiteSpace="nowrap"
                  >
                    {title}
                  </Anchor>
                </BorderBox>
              </List.listItem>
            ))}
          </List>
        )}
        {layout === 'sidebar' && (
          <Box as="nav" width="264px" mr="5,5">
            <Divider />
            <Box>
              <List divided>
                {navItems.map(({ title, href }) => (
                  <List.listItem key={title}>
                    <BorderBox
                      borderLeft="4"
                      borderColor={
                        router.pathname === href ? 'primary' : 'transparent'
                      }
                    >
                      <Anchor
                        as={Link}
                        href={href}
                        variant="matchText"
                        display="block"
                        fontWeight="semiBold"
                        px={[3, null, null, 4]}
                        py="3"
                      >
                        {title}
                      </Anchor>
                    </BorderBox>
                  </List.listItem>
                ))}
              </List>
            </Box>
            <Divider />
            <Box mt="5">
              <LogoutButton />
            </Box>
          </Box>
        )}
        <Box as="main" flex="1">
          {children}
        </Box>
      </FlexBox>
    </Stack>
  );
};

NavLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default NavLayout;
